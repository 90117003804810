<template>
  <div class="select">
    <div
      :class="['select-cash', `${select === 'cash' ? 'select-cash-active' : ''}`]"
      @click="onClickSelect('cash')"
    >
      <div class="content">
        <div class="select-cash-text">可提现：</div>
        <div class="select-cash-number">{{ totalBalance }}</div>
        <div class="select-cash-unit">元</div>
      </div>
    </div>
    <div
      :class="['select-coupon', `${select === 'coupon' ? 'select-coupon-active' : ''}`]"
      @click="onClickSelect('coupon')"
    >
      <div class="content">
        <div class="select-coupon-text">可兑换：</div>
        <div class="select-coupon-number">{{ couponTotalAmount }}</div>
        <div class="select-coupon-unit">元</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    select: {
      type: String,
      default: () => {
        return "cash";
      },
    },
    totalBalance: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    couponTotalAmount: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  methods: {
    onClickSelect(select) {
      this.$emit("on-select", select);
    },
  },
});
</script>

<style lang="less" scoped>
.select {
  &-cash {
    position: absolute;
    left: -10px;
    bottom: 0px;
    width: 160px;
    height: 72px;
    background-image: url("~@/assets/img/withdraw/cash.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    font-family: Source Han Sans SC;
    cursor: pointer;
    .content {
      position: absolute;
      bottom: 26px;
      left: 18px;
    }
    &-text {
      display: inline-block;
    }
    &-number {
      font-family: Akrobat;
      display: inline-block;
      font-weight: 800;
      font-size: 20px;
      color: #ffffff;
      line-height: 24px;
      margin-right: 2px;
    }
    &-unit {
      display: inline-block;
    }
    &-active {
      width: 180px;
      height: 80px;
      background-image: url("~@/assets/img/withdraw/cash_select.png");
    }
  }
  &-coupon {
    position: absolute;
    right: -10px;
    bottom: 0px;
    width: 160px;
    height: 72px;
    background-image: url("~@/assets/img/withdraw/coupon.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    font-family: Source Han Sans SC;
    cursor: pointer;
    .content {
      position: absolute;
      bottom: 26px;
      left: 18px;
    }
    &-text {
      display: inline-block;
    }
    &-number {
      font-family: Akrobat;
      display: inline-block;
      font-weight: 800;
      font-size: 20px;
      color: #ffffff;
      line-height: 24px;
      margin-right: 2px;
    }
    &-unit {
      display: inline-block;
    }
    &-active {
      width: 180px;
      height: 80px;
      background-image: url("~@/assets/img/withdraw/coupon_select.png");
    }
  }
}
</style>
