<template>
  <div class="withdraw">
    <div class="withdraw-bg">
      <img
        src="@/assets/img/withdraw/bg.png"
        class="withdraw-bg-img"
        v-if="select === 'cash'"
      />
      <img src="@/assets/img/withdraw/bg1.png" class="withdraw-bg-img" v-else />
    </div>
    <div class="withdraw-record" @click="onClickRecord">提<br />现<br />记<br />录</div>
    <div class="content">
      <div class="content-divider"></div>
      <div class="main">
        <div class="main-title">
          <img src="@/assets/img/withdraw/get.png" class="main-title-img" />
        </div>
        <div class="main-money">
          <div class="main-money-number">{{ totalRewardAmount }}</div>
          <div class="main-money-text">元</div>
        </div>
        <div class="main-number">
          <Select
            :select="select"
            :totalBalance="totalBalance"
            :couponTotalAmount="couponTotalAmount"
            @on-select="onClickSelect"
          ></Select>
        </div>
        <div class="main-rule">
          <div class="main-rule-withdraw" v-if="select === 'cash'">
            <Rule></Rule>
          </div>
          <div class="main-rule-coupon" v-else>
            <CouponRule
              :couponAmount="couponAmount"
              :cashCouponAmount="cashCouponAmount"
            ></CouponRule>
          </div>
        </div>
      </div>
      <div class="main-withdraw" @click="onClickWithdraw" v-if="select === 'cash'"></div>
    </div>
    <div v-if="select === 'cash' && !completeService && isWeXin && !hadBindOpenId">
      <CompleteService @click="onClickCompleteService"></CompleteService>
    </div>
    <loading text="正在提现中..." v-if="loading"></loading>
    <van-popup v-model:show="show" style="background-color: transparent; width: 100%">
      <div class="record">
        <div class="record-title"></div>
        <div class="record-list" v-if="recordList.length > 0">
          <div class="title">
            <div class="title-money">提现金额</div>
            <div class="title-time">提现时间</div>
            <div class="title-status">状态</div>
          </div>
          <div style="max-height: 350px; overflow-y: auto">
            <div class="item" v-for="(item, index) of recordList" :key="index">
              <div class="item-money">{{ item.amount }}元</div>
              <div class="item-time">{{ item.occurrenceTime }}</div>
              <div class="item-status">
                <span v-if="item.detailStatus === 'SUCCESS'">提现成功</span>
                <span v-else-if="item.detailStatus === 'FAIL'">提现失败</span>
                <span v-else-if="item.detailStatus === 'NOT_ENOUGH'">资金不足</span>
                <span v-else-if="item.detailStatus === 'FREQUENCY_LIMITED'"
                  >频率超限</span
                >
                <span v-else-if="item.detailStatus === 'SYSTEM_ERROR'">系统错误</span>
                <span v-else>提现中</span>
              </div>
            </div>
          </div>
        </div>
        <div class="record-empty" v-else>
          <img src="@/assets/img/withdraw/withdraw_empty.png" class="record-empty-img" />
        </div>
        <div class="record-close" @click="onClickCloseRecord"></div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="withdrawShow"
      style="width: 100%; background-color: transparent"
    >
      <div class="test">
        <div class="test-content">您确认要提现吗？</div>
        <div class="test-action">
          <div class="test-action-cancel" @click="withdrawShow = false">取消</div>
          <div class="test-action-divider"></div>
          <div class="test-action-confirm" @click="onConfirmWithdraw">确认</div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="noWxWithdraw"
      style="width: 100%; background-color: transparent"
    >
      <div class="no-wx">
        <div class="no-wx-content">
          <div class="no-wx-content-number">{{ totalBalance }}</div>
        </div>
        <div class="no-wx-close" @click="noWxWithdraw = false"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getURLParamsByKey, bindUserOpenId, getWechatCode } from "@/utils/index";
import loading from "@/views/components/loading.vue";
import {
  userAmountStatistics,
  getUserWithdrawRecord,
  userWithdraw,
  checkUserOpenId,
  bindOpenId,
} from "@/api/withdraw";
import Select from "./components/select.vue";
import Rule from "./components/rule.vue";
import CouponRule from "./components/coupon-rule.vue";
import CompleteService from "@/components/CompleteService/index.vue";
import { Notify } from "vant";
import { throttle } from "@/utils/throttle_debounce.js";
import Vue from "vue";
export default Vue.extend({
  components: { Select, Rule, CouponRule, CompleteService, loading },
  data() {
    return {
      show: false,
      select: "cash",
      totalRewardAmount: 0,
      totalBalance: 0,
      couponTotalAmount: 0,
      couponAmount: 0,
      cashCouponAmount: 0,
      noWxWithdraw: false,
      recordList: [],
      withdrawShow: false,
      completeService: false,
      isWeXin: false,
      hadBindOpenId: false,
      loading: false,
    };
  },
  created() {
    let currentUrl = window.location.href;
    const ua = window.navigator.userAgent.toLowerCase();
    this.isWeXin = ua.indexOf("micromessenger") !== -1;
    checkUserOpenId(this.$store.getters.inviteToken).then((res) => {
      if (!res) {
        this.hadBindOpenId = false;
      } else {
        this.hadBindOpenId = true;
      }
    });
    this.completeService = this.$store.getters.completeService === "true" ? true : false;
    let code = getURLParamsByKey("code") || "";
    if (code !== "") {
      localStorage.setItem("wxCode", code);
      this.$store.commit("SET_WXCODE", code);
      history.back();
    } else if (code == "" && (this.$store.getters.wxcode || "") !== "") {
      bindOpenId(this.$store.getters.wxcode, this.$store.getters.inviteToken).then(
        (res) => {
          console.log(res);
        }
      );
      localStorage.removeItem("wxCode");
      this.$store.commit("SET_WXCODE", "");
    }
    this.GetUserAmountStatistics();
  },
  methods: {
    onClickWithdraw() {
      if (!this.isWeXin && !this.hadBindOpenId) {
        this.noWxWithdraw = true;
      } else if (this.totalBalance > 0) {
        this.withdrawShow = true;
      } else {
        Notify("当前无可提现金额");
      }
      // this.withdrawShow = true;
    },
    onClickRecord() {
      this.GetUserWithdrawRecord();
      this.show = true;
    },
    onClickCloseRecord() {
      this.show = false;
    },
    onClickSelect(select) {
      if (this.select !== select) {
        this.select = select;
      }
    },
    GetUserAmountStatistics() {
      userAmountStatistics(this.$store.getters.inviteToken).then((res) => {
        console.log(res);
        this.totalRewardAmount = res.totalRewardAmount;
        this.totalBalance = res.totalBalance;
        this.couponTotalAmount = res.couponTotalAmount;
        this.couponAmount = res.couponAmount;
        this.cashCouponAmount = res.cashCouponAmount;
      });
    },
    GetUserWithdrawRecord() {
      getUserWithdrawRecord(this.$store.getters.inviteToken).then((res) => {
        this.recordList = res;
      });
    },
    onClickCompleteService() {
      localStorage.setItem("completeService", "true");
      this.completeService = true;
      if (this.isWeXin && !this.hadBindOpenId) {
        const scope = "snsapi_base"; //弹出授权页面
        const currentUrl = window.location.href;
        const baseUrlEncode = encodeURIComponent(currentUrl);
        const appid = "wx17081ce61493aba9";
        const urls = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${baseUrlEncode}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;
        location.replace(urls);
      }
    },
    onConfirmWithdraw: throttle(function () {
      this.loading = true;
      userWithdraw(this.totalBalance, this.$store.getters.inviteToken)
        .then((res) => {
          if (res.code == "ERROR") {
            Notify(res.message);
            this.loading = false;
            this.withdrawShow = false;
            this.GetUserAmountStatistics();
          } else {
            Notify({
              type: "success",
              message: "提现申请已提交，预计15分钟内到账",
            });
            this.loading = false;
            this.withdrawShow = false;
            this.GetUserAmountStatistics();
          }
        })
        .catch(() => {
          this.GetUserAmountStatistics();
          this.loading = false;
        });
    }, 500),
  },
});
</script>
<style lang="less" scoped>
.withdraw {
  width: 100vw;
  position: relative;
  &-bg {
    width: 100%;
    &-img {
      width: 100%;
    }
  }
  &-record {
    position: absolute;
    right: 0px;
    top: 140px;
    z-index: 30;
    background: url("~@/assets/img/invite/rule_bg.png") no-repeat;
    background-size: 100% 100%;
    font-weight: bold;
    font-size: 12px;
    color: #2a1000;
    width: 28px;
    height: 86px;
    text-align: center;
    padding: 10px 0;
  }
  .content {
    position: absolute;
    top: 0px;
    left: 20px;
    right: 20px;
    margin-top: 220px;
    z-index: 20;
    .content-divider {
      height: 33px;
      background-image: url("~@/assets/img/withdraw/divider.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      z-index: 25;
    }
    .main {
      background: #ffffff;
      box-shadow: 0px 5px 27px 0px rgba(255, 12, 0, 0.2);
      border-radius: 0px 0px 10px 10px;
      padding: 13px;
      margin-top: -1px;
      position: relative;
      z-index: 21;
      padding-top: 20px;
      &-title {
        font-size: 0px;
        display: flex;
        justify-content: center;
        &-img {
          width: 75px;
          height: 23px;
        }
      }
      &-money {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 20px;
        &-number {
          font-weight: bold;
          font-size: 73px;
          font-style: italic;
          background: linear-gradient(125deg, #ff8d12 0%, #ff5a00 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 73px;
          padding-right: 10px;
          position: relative;
          z-index: 20;
        }
        &-text {
          font-weight: 800;
          font-size: 33px;
          color: #ff5b01;
          font-style: italic;
        }
      }
      &-number {
        margin-top: 14px;
        height: 80px;
        position: relative;
      }

      &-rule {
        margin-top: 20px;
      }
      &-withdraw {
        width: 285px;
        height: 58px;
        background-image: url("~@/assets/img/withdraw/btn_withdraw.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 0 auto;
        margin-top: 26px;
        position: fixed;
        bottom: 40px;
        z-index: 200;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.record {
  width: 100%;
  padding: 0 30px;
  &-title {
    width: 225px;
    height: 34px;
    background-image: url("~@/assets/img/withdraw/record_title.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  &-list {
    background-color: #ffffff;
    box-shadow: 0px 5px 4px 0px rgba(99, 155, 249, 0.2);
    border-radius: 10px;
    padding: 0 10px;
    padding-top: 40px;
    margin-top: -24px;
    min-height: 300px;
    padding-bottom: 20px;
    .title {
      display: flex;
      height: 34px;
      background: #f3f3f3;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      &-money {
        width: 80px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-time {
        flex: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-status {
        width: 80px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .item {
      display: flex;
      height: 34px;
      font-weight: 400;
      font-size: 12px;
      color: #000000;
      &-money {
        width: 80px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-time {
        flex: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-status {
        width: 80px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-empty {
    background-color: #ffffff;
    box-shadow: 0px 5px 4px 0px rgba(99, 155, 249, 0.2);
    border-radius: 10px;
    padding: 0 10px;
    padding-top: 40px;
    margin-top: -24px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      width: 160px;
    }
  }
  &-close {
    width: 30px;
    height: 30px;
    background-image: url("~@/assets/img/withdraw/close.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.no-wx {
  width: 100%;
  padding: 0 23px;
  &-content {
    height: 181px;
    background-image: url("~@/assets/img/withdraw/withdraw_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    &-number {
      width: 120px;
      position: absolute;
      bottom: 54px;
      right: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Poppins;
      font-weight: bold;
      font-size: 68px;
      font-style: italic;
      background: linear-gradient(-15deg, #fd6e1b 21%, #fdad02 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &-close {
    width: 30px;
    height: 30px;
    background-image: url("~@/assets/img/withdraw/close.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 10px;
  }
}
.test {
  background-color: #ffffff;
  margin: 0 20px;
  border-radius: 20px;
  font-size: 18px;
  &-content {
    padding: 40px 20px;
  }
  &-action {
    display: flex;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    &-cancel {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    &-divider {
      width: 1px;
      height: 40px;
      background-color: #e6e6e6;
      margin: 0 10px;
    }
    &-confirm {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ff8845;
    }
  }
}
</style>
