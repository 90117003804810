<template>
  <div class="rule">
    <div class="rule-discount">
      <div class="rule-discount-content">
        <div class="rule-discount-content-number">{{ couponAmount }}</div>
        <div class="rule-discount-content-unit">元</div>
      </div>
    </div>
    <div class="rule-use">
      <div class="rule-use-title"></div>
      <div class="rule-use-content">
        <div class="item">
          <div class="item-point"></div>
          <div class="item-text">
            优惠券为满减券，购物金额需达到一定门槛方可使用，每次仅可使用一张;
          </div>
        </div>
        <div class="item">
          <div class="item-point"></div>
          <div class="item-text">代金券为无门槛抵扣券，每次仅可使用一张;</div>
        </div>
        <div class="item">
          <div class="item-point"></div>
          <div class="item-text">优惠券、代金券不可混合使用;</div>
        </div>
        <div class="item">
          <div class="item-point"></div>
          <div class="item-text">优惠券、代金券均不可提现、找零。</div>
        </div>
      </div>
    </div>
    <div class="rule-coupon">
      <div class="rule-coupon-content">
        <div class="rule-coupon-content-number">{{ cashCouponAmount }}</div>
        <div class="rule-coupon-content-unit">元</div>
      </div>
    </div>
    <div class="rule-exchange">
      <div class="rule-exchange-title"></div>
      <div class="rule-exchange-content">
        <div class="item">
          <div class="item-point"></div>
          <div class="item-text">
            <div>方法一：安装乡农商城APP</div>
            <div>
              ①安卓手机在应用商店搜索“乡农商城”进行下载安装，或使用浏览器扫码进行下载安装
            </div>
            <div>②通过手机号、验证码登录乡农商城</div>
            <div>③进入“我的”，打开优惠券页面即可查看优惠券/代金券</div>
          </div>
        </div>
        <div class="code">
          <img src="@/assets/img/withdraw/app_code.png" class="code-img" />
        </div>
        <div class="item">
          <div class="item-point"></div>
          <div class="item-text">
            <div>方法二：关注公众号</div>
            <div>①关注<span style="color: #ff5b01">乡农交易网</span>公众号</div>
            <div>
              ②点击底部菜单“<span style="color: #ff5b01">乡农商城</span>”， 点击“<span
                style="color: #ff5b01"
                >商城入口</span
              >”进入商城
            </div>
            <div>③通过手机号、验证码登录乡农商城</div>
            <div>④进入“我的”，打开优惠券页面即可查看优惠券/代金券</div>
          </div>
        </div>
        <div class="code">
          <img src="@/assets/img/withdraw/mini_code.png" class="code-img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    couponAmount: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    cashCouponAmount: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
});
</script>
<style lang="less" scoped>
.rule {
  &-discount {
    width: 203px;
    height: 20px;
    background-image: url("~@/assets/img/withdraw/discount_total.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    position: relative;
    &-content {
      position: absolute;
      right: 28px;
      top: -24px;
      &-number {
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-size: 17px;
        color: #391916;
        line-height: 18px;
        width: 30px;
        margin-right: 3px;
        display: inline-flex;
        justify-content: center;
      }
      &-unit {
        display: inline-block;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-size: 17px;
        color: #391916;
      }
    }
  }
  &-use {
    margin-top: 25px;
    &-title {
      width: 170px;
      height: 15px;
      background-image: url("~@/assets/img/withdraw/use_way.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &-content {
      margin-top: 14px;
      .item {
        display: flex;
        align-items: flex-start;

        &-point {
          width: 7px;
          height: 7px;
          background-color: #391916;
          border-radius: 50%;
          flex-shrink: 0;
          margin-top: 7px;
          margin-right: 4px;
        }
        &-text {
          font-family: Source Han Sans SC;
          font-weight: 400;
          font-size: 13px;
          color: #391916;
        }
      }
    }
  }
  &-coupon {
    width: 203px;
    height: 20px;
    background-image: url("~@/assets/img/withdraw/coupon_total.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 38px;
    position: relative;
    &-content {
      position: absolute;
      right: 28px;
      top: -24px;
      &-number {
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-size: 17px;
        color: #391916;
        line-height: 18px;
        width: 30px;
        margin-right: 3px;
        display: inline-flex;
        justify-content: center;
      }
      &-unit {
        display: inline-block;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        font-size: 17px;
        color: #391916;
      }
    }
  }
  &-exchange {
    margin-top: 26px;
    &-title {
      width: 170px;
      height: 15px;
      background-image: url("~@/assets/img/withdraw/exchange_way.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &-content {
      .item {
        margin-top: 14px;
        display: flex;
        align-items: flex-start;
        &-point {
          width: 7px;
          height: 7px;
          background-color: #391916;
          border-radius: 50%;
          flex-shrink: 0;
          margin-top: 7px;
          margin-right: 4px;
        }
        &-text {
          font-family: Source Han Sans SC;
          font-weight: 400;
          font-size: 13px;
          color: #391916;
        }
      }
      .code {
        display: flex;
        justify-content: center;
        height: 120px;
        margin-top: 8px;
        &-img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}
</style>
