<template>
  <div class="rule">
    <div class="rule-title"></div>
    <div class="rule-content">
      <div>
        1、现金提现用户必须绑定有效的微信账号，微信提现奖励将以微信红包的形式发放至用户绑定的微信账户；
      </div>
      <div>2、微信提现若因用户账户信息错误导致提现有误，平台不负责补偿；</div>
      <div>
        3、若用户未收到微信提现现金或遇到其他提现问题，请在提现后的7天内联系客服。提供详细的申请记录和问题描述，客服将在3个工作日内进行处理并回复。客服：400-005-8008
      </div>
      <div>
        4、用户如发现红包金额错误、未发放等问题，请提供相关凭证和截图，平台将尽快核实并处理。
      </div>
      <div>5、如发现用户恶意操作或其他违规行为，平台有权取消提现资格及相关奖励。</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({});
</script>
<style lang="less" scoped>
.rule {
  &-title {
    width: 130px;
    height: 20px;
    background-image: url("~@/assets/img/withdraw/withdraw_rule.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
  }
  &-content {
    margin-top: 18px;
    font-weight: 400;
    font-size: 14px;
    color: #565656;
    line-height: 24px;
    text-indent: 2em;
  }
}
</style>
